<template>
  <k-input
    v-bind="$attrs"
    v-on="$listeners"
    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
    :type="showPassword ? 'text' : 'password'"
    @click:append="showPassword = !showPassword"
  />
</template>

<script>
export default {
  data() {
    return {
      showPassword: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

::v-deep .v-input .v-icon {
  color: $kod-grey !important;
}
</style>
