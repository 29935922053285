import { render, staticRenderFns } from "./k-password.vue?vue&type=template&id=4f45e5d9&scoped=true&"
import script from "./k-password.vue?vue&type=script&lang=js&"
export * from "./k-password.vue?vue&type=script&lang=js&"
import style0 from "./k-password.vue?vue&type=style&index=0&id=4f45e5d9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f45e5d9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KInput from '@web/components/core/k-input'
installComponents(component, {KInput})
